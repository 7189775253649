﻿import { Plugin } from '@ckeditor/ckeditor5-core';
import { ContextualBalloon, createDropdown } from '@ckeditor/ckeditor5-ui';
import GAMEPAD from '../images/gamepad.svg';
import GameInputView from './gameinputview';

export default class GameInputUI extends Plugin {

    static get requires() {
        return [ContextualBalloon];
    }

    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('gameInput', locale => {
            const command = editor.commands.get('InsertGameInput');
            this.dropdownView = createDropdown(locale);

            this.dropdownView.bind('isEnabled').to(command);

            this.dropdownView.buttonView.set({
                icon: GAMEPAD,
                label: 'GAMEPAD',
            });

            let gameInputView;

            this.dropdownView.on('change:isOpen', () => {
                if (gameInputView) {
                    return;
                }

                gameInputView = this._createFormView();
                this.dropdownView.panelView.children.add(gameInputView);
                gameInputView.delegate('execute').to(this.dropdownView);
            });

            return this.dropdownView;
        });
    }

    _createFormView() {
        const editor = this.editor;
        const gameInputView = new GameInputView(editor.locale);

        this.listenTo(gameInputView, 'InsertGameInput', (evt, data) => {
            if (data.length > 0) {
                editor.execute('InsertGameInput', data);
            }
            this._hideUI();
        });

        this.listenTo(gameInputView, 'cancel', () => {
            this._hideUI();
        });

        return gameInputView;
    }

    _hideUI() {
        this.dropdownView.isOpen = false;
        this.editor.editing.view.focus();
    }
}