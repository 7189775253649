﻿import { Command } from '@ckeditor/ckeditor5-core';

export default class GameInputCommand extends Command {
    refresh() {
        const imageUtils = this.editor.plugins.get('ImageUtils');
        this.isEnabled = imageUtils.isImageAllowed();
    }

    execute(key) {
        const keyList = key.split(',');
        this.insertImage(keyList);
    }

    insertImage(keyList) {
        const editor = this.editor;
        const model = editor.model;

        return model.change(writer => {
            const attack = writer.createElement('attackButton', { keyList: keyList });
            model.insertContent(attack);
        });
    }
}
