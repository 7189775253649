﻿// abbreviation/abbreviationui.js

import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView, ContextualBalloon } from '@ckeditor/ckeditor5-ui';
import FormView from './abbreviationview';								// ADDED


export default class AbbreviationUI extends Plugin {
    static get requires() {
        return [ContextualBalloon];
    }

    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('abbreviation', () => {
            const button = new ButtonView();


            // Create the balloon and the form view.
            this._balloon = this.editor.plugins.get(ContextualBalloon);
            this.formView = this._createFormView();


            button.label = 'Abbreviation';
            button.tooltip = true;
            button.withText = true;

            this.listenTo(button, 'execute', () => {
                const selection = editor.model.document.selection;
                const title = 'What You See Is What You Get';
                const abbr = 'WYSIWYG';

                editor.model.change(writer => {
                    editor.model.insertContent(
                        writer.createText(abbr, { abbreviation: title })
                    );
                });
            });

            // Show the UI on button click.
            this.listenTo(button, 'execute', () => {
                this._showUI();
            });

            return button;
        });
    }



    _createFormView() {
        const editor = this.editor;
        const formView = new FormView(editor.locale);

        return formView;
    }


    _getBalloonPositionData() {
        const view = this.editor.editing.view;
        const viewDocument = view.document;
        let target = null;

        // Set a target position by converting view selection range to DOM.
        target = () => view.domConverter.viewRangeToDom(
            viewDocument.selection.getFirstRange()
        );

        return {
            target
        };
    }

    _showUI() {
        this._balloon.add({
            view: this.formView,
            position: this._getBalloonPositionData()
        });

        this.formView.focus();
    }





}
