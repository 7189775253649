﻿import { icons } from '@ckeditor/ckeditor5-core'; // ADDED
import {
    ButtonView,
    IconView,
    ToolbarView,
    View,
    submitHandler
} from '@ckeditor/ckeditor5-ui';
import ToolbarLineBreakView from '@ckeditor/ckeditor5-ui/src/toolbar/toolbarlinebreakview';

import './gameinput.css';
//import './styles.css';

import DN from '../images/DN.svg';
import LT from '../images/LT.svg';
import RT from '../images/RT.svg';
import UP from '../images/UP.svg';

import N from '../images/N.svg';
import NN from '../images/NN.svg';

import LDN from '../images/LDN.svg';
import LLT from '../images/LLT.svg';
import LRT from '../images/LRT.svg';
import LUP from '../images/LUP.svg';

import LD from '../images/LD.svg';
import LU from '../images/LU.svg';
import RD from '../images/RD.svg';
import RU from '../images/RU.svg';

import LLD from '../images/LLD.svg';
import LLU from '../images/LLU.svg';
import LRD from '../images/LRD.svg';
import LRU from '../images/LRU.svg';


import LP from '../images/LP.svg';
import RP from '../images/RP.svg';
import LK from '../images/LK.svg';
import RK from '../images/RK.svg';

import LPRK from '../images/LPRK.svg';
import RPLK from '../images/RPLK.svg';

import APLK from '../images/APLK.svg';
import APRK from '../images/APRK.svg';
import LPAK from '../images/LPAK.svg';
import RPAK from '../images/RPAK.svg';

import AL from '../images/AL.svg';
import AR from '../images/AR.svg';

import AP from '../images/AP.svg';
import AK from '../images/AK.svg';
import AB from '../images/AB.svg';

import BackSpace from '../images/BackSpace.svg';


export const arrowIconList = [
    [LLU, NN, LUP, NN, LRU],
    [NN, LU, UP, RU, NN],
    [LLT, LT, N, RT, LRT],
    [NN, LD, DN, RD, NN],
    [LLD, NN, LDN, NN, LRD]
];

export const attackIconList = [
    [LPRK, APLK, APRK, RPLK],
    [NN, LP, RP, AP],
    [NN, LK, RK, AK],
    [NN, AL, AR, AB],
    [NN, LPAK, RPAK, NN]
];

export const arrowList = [
    ['LLU', 'NN', 'LUP', 'NN', 'LRU'],
    ['NN', 'LU', 'UP', 'RU', 'NN'],
    ['LLT', 'LT', 'N', 'RT', 'LRT'],
    ['NN', 'LD', 'DN', 'RD', 'NN'],
    ['LLD', 'NN', 'LDN', 'NN', 'LRD']
];

export const attackList = [
    ['LPRK', 'APLK', 'APRK', 'RPLK'],
    ['NN', 'LP', 'RP', 'AP'],
    ['NN', 'LK', 'RK', 'AK'],
    ['NN', 'AL', 'AR', 'AB'],
    ['NN', 'LPAK', 'RPAK', 'NN']
];


export default class GameInputView extends View {
    constructor(locale) {
        super(locale);
        const bind = this.bindTemplate;

        this.locale = locale;

        this.arrow = { buttonList: arrowList, iconList: arrowIconList, cssClass: 'ck-gameInput-arrow-grid-box', colCount: 5, rowCount: 5 };
        this.Attack = { buttonList: attackList, iconList: attackIconList, cssClass: 'ck-gameInput-attack-grid-box', colCount: 4, rowCount: 5 };

        this.arrowItems = this._createGridBox(this.arrow);
        this.AttackItems = this._createGridBox(this.Attack);

        this.toolbarView = new ToolbarView(locale);
        this.functionToolbarView = new ToolbarView(locale);
        this.buttonToolbarView = new ToolbarView(locale);
        this.inputButtonList = new Array();

        this.initToolView();
        this.initFunctionToolbarView();
        this.initbuttonToolbarView();

        this.setTemplate({
            tag: 'div',
            attributes: {
                class: ['ck-gameInput']
            },

            children: [
                {
                    tag: 'div',
                    attributes: {
                        class: ['ck-gameInputChild'],
                    },

                    children: [
                        {
                            tag: 'div',
                            attributes: {
                                class: ['ck-gameInput-arrow__grid']
                            },

                            children: this.arrowItems
                        },

                        {
                            tag: 'div',
                            attributes: {
                                class: ['ck-gameInput-function']
                            },

                            children: this.functionToolbarView.items
                        },
                        {
                            tag: 'div',
                            attributes: {
                                class: ['ck-gameInput-attack__grid']
                            },

                            children: this.AttackItems
                        }

                    ]
                }
                ,
                this.toolbarView,
                this.buttonToolbarView
            ],
        });
    }

    render() {
        super.render();

        submitHandler({
            view: this
        });
    }


    initToolView() {
        this.clearInsertButton();
    }

    clearInsertButton() {
        console.log('initInsertButton');
        this.inputButtonList = new Array();

        const InputText = new View();
        InputText.element = document.createTextNode('Input Command');
        const newLine = new ToolbarLineBreakView(this.locale)

        this.toolbarView.items.clear();
        this.toolbarView.items.add(InputText);
        this.toolbarView.items.add(newLine);
    }

    initFunctionToolbarView() {
        const BackButtonView = this._createButton('Back', BackSpace, 'ck-button-BackSpace');
        const clearButtonView = this._createButton('Clear', icons.eraser, 'ck-button-clear');
        const newLine = new ToolbarLineBreakView(this.locale)

        BackButtonView.on('execute', () => {
            this.inputButtonList.pop();
            this.toolbarView.items.remove(this.toolbarView.items.length - 1);
        });

        clearButtonView.on('execute', () => {
            this.clearInsertButton();
        });

        this.functionToolbarView.items.add(BackButtonView);
        this.functionToolbarView.items.add(newLine);
        this.functionToolbarView.items.add(clearButtonView);
    }

    initbuttonToolbarView() {
        const saveButtonView = this._createButton('Save', icons.check, 'ck-button-save');
        const cancelButtonView = this._createButton('Cancel', icons.cancel, 'ck-button-cancel');


        saveButtonView.on('execute', () => {
            const inputData = this.inputButtonList.toString();
            this.fire('InsertGameInput', inputData);

            this.clearInsertButton();
        });


        cancelButtonView.on('execute', () => {
            this.clearInsertButton();
            this.fire('cancel');
        });

        this.buttonToolbarView.items.add(saveButtonView);
        this.buttonToolbarView.items.add(cancelButtonView);
    }



    focus() {
        //this.childViews.first.focus();
        //this.items.get(0)!.focus();
    }

    _createGridBox(target) {
        const boxes = [];
        const totalCount = target.colCount * target.rowCount;

        for (let index = 0; index < totalCount; index++) {
            const row = Math.floor(index / target.colCount);
            const column = index % (target.colCount);

            boxes.push(this._createGridButton(target.iconList, target.buttonList, target.cssClass, row, column));

            if (column == target.colCount - 1) {
                const newLine = new ToolbarLineBreakView(this.locale);
                boxes.push(newLine);
            }
        }

        return this.createCollection(boxes);
    }

    _createGridButton(iconList, buttonList, cssClass, row, column) {
        const button = this._createButton('', iconList[row][column], cssClass);

        button.extendTemplate({
            attributes: {
                'data-row': row,
                'data-column': column
            }
        });

        if (buttonList[row][column] != 'NN') {
            button.on('execute', () => { this._addCommand(iconList, buttonList, row, column); });
        }

        return button;
    }

    _createButton(label, icon, className) {
        const button = new ButtonView();
        button.set({ label, icon: icon, tooltip: true, class: className });

        return button;
    }

    _addCommand(iconList, buttonList, row, column) {
        const inputIcon = iconList[row][column];
        const inputButton = buttonList[row][column];

        const icon = new IconView();
        icon.content = inputIcon;

        this.toolbarView.items.add(icon);
        this.inputButtonList.push(inputButton);
    }
}
