﻿import { Plugin } from '@ckeditor/ckeditor5-core';
/*import {
    getImgViewElementMatcher
} from '@ckeditor/ckeditor5-image/src/image/utils';*/
import GameInputCommand from './gameinputcommand';

export default class GameInputEditing extends Plugin {
    init() {
        const editor = this.editor;
        const model = this.editor.model;
        const schema = editor.model.schema;

        schema.register('attackButton', {
            inheritAllFrom: '$inlineObject',
            allowAttributes: ['keyList']
        });

        this._setupConversion();

        editor.commands.add('InsertGameInput', new GameInputCommand(editor));
    }

    _setupConversion() {
        const editor = this.editor;
        const conversion = editor.conversion;

        conversion.for('dataDowncast')
            .elementToStructure({
                model: 'attackButton',
                view: (modelElement, { writer }) => this.createAttackButtonViewElement(modelElement, writer)
            });

        conversion.for('editingDowncast')
            .elementToStructure({
                model: 'attackButton',
                view: (modelElement, { writer }) => this.createAttackButtonViewElement(modelElement, writer)
            });

        conversion.for('downcast').attributeToElement({
            model: 'attackButton',

            view: (modelAttributeValue, conversionApi) => {
                const { writer } = conversionApi;
                return writer.createAttributeElement('attackButton', {
                    keyList: modelAttributeValue
                });
            }
        });

        /*        conversion.for('upcast')
                    .elementToElement({
                        view: getImgViewElementMatcher(editor, 'imageInline'),
                        model: (viewImage, { writer }) => writer.createElement(
                            'imageInline',
                            viewImage.hasAttribute('src') ? { src: viewImage.getAttribute('src') } : undefined
                        )
                    });
        */
        editor.conversion
            .for('upcast')
            .elementToElement({
                view: {
                    name: 'span', class: 'ck-gameInput-command'
                },
                model: (viewElement, { writer }) => {
                    let srcList = new Array();
                    for (let i = 0; i < viewElement.childCount; i++) {
                        const child = viewElement.getChild(i);
                        const url = child.getAttribute('src');
                        const path = '/images/';
                        const firstIndex = url.indexOf(path) + 8;
                        const lastIndex = url.indexOf('svg') - 1;

                        srcList.push(url.substring(firstIndex, lastIndex));
                    }
                    return writer.createElement('attackButton', { keyList: srcList });

                }, converterPriority: 'high'
            });
    }

    createAttackButtonViewElement(modelElement, writer) {
        const keyList = modelElement.getAttribute('keyList');
        const srcList = new Array();

        keyList.forEach((sourceDefinition, index) => {
            const src = writer.createEmptyElement('img', { src: 'https://tkmv.net/images/' + sourceDefinition + '.svg' });
            srcList.push(src);
        });

        return writer.createContainerElement('span', { class: 'ck-gameInput-command' }, srcList)
    }
}


/*
return writer.createContainerElement('span', { class: 'ck-gameInput-command-inline' },
    writer.createElement('attackButton', { class: '' }, srcList)
);*/